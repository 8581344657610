<template>
  <div>
    <form
      class="px-6 pt-6 pb-8 bg-gray-100"
      novalidate
      @submit.prevent
    >
      <div class="space-y-4">
        <div
          v-for="(v, index) in $v.formResponses.$each.$iter"
          :key="index"
        >
          <section>
            <label class="block relative">
              <span class="text-gray-800 text-sm">Full name</span>
              <input
                v-model="v.fullName.$model"
                type="text"
                class="form-input mt-1 w-full"
                name="fullName"
                placeholder="Full name"
              >
            </label>
            <ErrorsInline v-if="errors">
              <span v-if="!v.fullName.required">This field is required</span>
              <span v-if="!v.fullName.validateFullName">You must use a first and second name</span>
            </ErrorsInline>
          </section>

          <section class="mt-4">
            <label class="block relative">
              <span class="text-gray-800 text-sm">Email</span>
              <input
                v-model="v.email.$model"
                type="text"
                class="form-input mt-1 w-full"
                placeholder="Email"
              >
            </label>
            <ErrorsInline v-if="errors">
              <span v-if="!v.email.email">Needs to be a valid email</span>
              <span v-if="!v.email.required">This field is required</span>
            </ErrorsInline>
          </section>
          <div class="text-right">
            <button
              v-if="formResponses.length > 1"
              class="text-red-500 text-xs text-right focus:outline-none"
              @click="formResponses.splice(index, 1)"
            >
              <Icon
                view-box="0 0 14 14"
                class="w-2 h-2 transform rotate-45"
              >
                <Plus />
              </Icon>
              Remove
            </button>
          </div>
        </div>
      </div>

      <button
        v-if="formResponses.length < 3"
        class="text-secondary text-sm mt-4 focus:outline-none"
        @click="formResponses.push({fullName: '', email: ''})"
      >
        <Icon
          view-box="0 0 14 14"
          class="w-2 h-2"
        >
          <Plus />
        </Icon>
        Add Member
      </button>

      <section class="mx-auto text-right">
        <BaseButton
          class="mt-8"
          :loading="isLoading"
          @click="submit"
        >
          Send invites
        </BaseButton>

        <div class="mt-2 flex justify-center">
          <ErrorsInline
            v-if="empty && uiState === 'submit clicked'"
            class="text-center"
          >
            Please fill in the required fields above
          </ErrorsInline>
          <ErrorsInline
            v-if="errorMessage"
            class="text-center"
          >
            {{ errorMessage }}
          </ErrorsInline>
        </div>
      </section>
    </form>
  </div>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'

import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { validateFullName } from '@utils/nameValidation'
import { mapGetters } from 'vuex'
import membersApi from '@api/members'

export default {
  components: {
    ErrorsInline,
    Icon,
    Plus
  },

  mixins: [validationMixin],

  data() {
    return {
      uiState: 'submit not clicked',
      isLoading: false,
      errors: false,
      errorMessage: null,
      empty: true,

      formResponses: [
        {
          fullName: null,
          email: null
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    }),

    hasSidebarSlot() {
      return !!this.$slots.sidebar
    }
  },

  validations: {
    formResponses: {
      $each: {
        fullName: {
          required,
          validateFullName
        },
        email: {
          email,
          required
        }
      }
    }
  },

  methods: {
    submit() {
      this.empty = !this.$v.formResponses.$anyDirty
      this.errors = this.$v.formResponses.$anyError
      this.uiState = 'submit clicked'
      this.errorMessage = null

      if (this.errors === false && this.empty === false) {
        this.isLoading = true

        let responses = []

        this.formResponses.forEach(member => {
          responses.push(membersApi.create(this.organisationId, member))
        })

        let errors = []

        Promise.allSettled(responses)
          .then(results => {
            results.forEach((result, index) => {
              if (result.status === 'fulfilled') {
                // Remove from array
                let member = this.formResponses.find(member => member.email === result.value.email)
                this.formResponses.splice(this.formResponses.indexOf(member), 1)
              }
              if (result.status === 'rejected') {
                if (result.reason.response.data && result.reason.response.data.message) {
                  errors.push(result.reason.response.data.message)
                }
              }
            })
          })
          .finally(() => {
            this.isLoading = false

            if (errors.length > 0) {
              this.errorMessage = errors.join(', ')
              return
            }
            this.$emit('createdTeamMember')
            this.$emit('close')
          })
      }
    }
  }
}
</script>
